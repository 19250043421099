import React, { useState, useEffect } from 'react';

const Notifications = ({ notifications, campaign }) => {
  const [renderedItems, setRenderedItems] = useState([]);
  const [oldCampaign, setOldCampaign] = useState(campaign);
  

  useEffect(() => {
    setRenderedItems((prevRenderedItems) => [...prevRenderedItems, ...notifications]);
  }, [notifications]);

  function start_new_campaign(){
    if (oldCampaign !== campaign){
      setRenderedItems([])
      setOldCampaign(campaign)

    }
  }

  return (
    
    <div className='component'>
      {start_new_campaign()}
       <h3>Notifications Section</h3>
    
    <div
      style={{
        overflowY: 'scroll',
        maxHeight: '450px', // Adjust the height based on your needs
        border: '1px solid black',
        padding: '30px',
        minHeight: '450px'
      }}
    >
        
      {renderedItems.map((item, index) => (
        <div key={index}>{item}     <hr/></div>
    
      ))}
    </div>
    </div>
  );
};

export default Notifications;

