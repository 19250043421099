// import React, { useState } from 'react';
// import axios from 'axios';
// import { base_endpoint } from '../js_resources/initial_states';

// const CampaignManagement = ({ campaign_data, last_updated_time }) => {

//   const [selectedCampaignOption, setSelectedCampaignOption] = useState(campaign_data.campaign.simulation);
//   const [selectedWindowOption, setSelectedWindowOption ] = useState(campaign_data.campaign.window_state);
//   const [selectedDoorOption, setSelectedDoorOption ] = useState(campaign_data.campaign.door_state);
//   const [selectedBlindOption, setSelectedBlindOption ] = useState(campaign_data.campaign.blind_state);
//   const [selectedAirPurifierOption, setSelectedAirPurifierOption ] = useState(campaign_data.campaign.air_purifier_state);
//   const [selectedAirConditionOption, setSelectedAirConditionOption ] = useState(campaign_data.campaign.air_condition_state);
  

//   const handleSelectChange = async (event) => {
   
    
//     let put_data = null
//     if (event.target.id === "campaign" ){
//       put_data = { simulation: event.target.value }
//       setSelectedCampaignOption(event.target.value);
//     }
//     else if(event.target.id === "window" ){
//       put_data = { window_state: event.target.value }
//       setSelectedWindowOption(event.target.value);
//     }
//     else if(event.target.id === "door" ){
//       put_data = { door_state: event.target.value }
//       setSelectedDoorOption(event.target.value);
//     }
//     else if(event.target.id === "blind" ){
//       put_data = { blind_state: event.target.value }
//       setSelectedBlindOption(event.target.value);
//     }
//     else if(event.target.id === "air_purifier" ){
//       put_data = { air_purifier_state: event.target.value }
//       setSelectedAirPurifierOption(event.target.value);
//     }
//     else if(event.target.id === "air_condition" ){
//       put_data = { air_condition_state: event.target.value }
//       setSelectedAirConditionOption(event.target.value);
//     }
  

//     try {
//       const response = await axios.put(base_endpoint + "campaign", put_data);
//       if (response.status === 200) {
        
//       } else {
//         alert('Error');
//       }
//     } catch (error) {
//       alert('Error');
//     }
//   };

  

//   return (
//     <div className='component' >
      
//       <h3 >Campaign Section : {campaign_data.campaign.simulation}</h3>
//         <div style={{border: '1px solid black', padding: '30px',}}>
//         <select value={selectedCampaignOption} onChange={handleSelectChange} id="campaign">
//           {campaign_data.campaign_options.map((option, index) => (
//             <option key={index} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>
//         <p>Last updated {last_updated_time} Seconds</p> 
//         <h3>Manual Overide</h3> 
//         <p><b>Window</b>: {campaign_data.campaign.window_state}</p>
//         <select value={selectedWindowOption} onChange={handleSelectChange} id="window">
//           {campaign_data.element_state_options.map((option, index) => (
//             <option key={index} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>

//         <p>Door: {campaign_data.campaign.door_state}</p>
//         <select value={selectedDoorOption} onChange={handleSelectChange} id="door">
//           {campaign_data.element_state_options.map((option, index) => (
//             <option key={index} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>

//         <p>Blinds: {campaign_data.campaign.blind_state}</p>
//         <select value={selectedBlindOption} onChange={handleSelectChange} id="blind">
//           {campaign_data.element_state_options.map((option, index) => (
//             <option key={index} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>

//         <p>Air Purifier : {campaign_data.campaign.air_purifier_state}</p>
//         <select value={selectedAirPurifierOption} onChange={handleSelectChange} id="air_purifier">
//           {campaign_data.element_state_options.map((option, index) => (
//             <option key={index} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>

//         <p>Air Condition : {campaign_data.campaign.air_condition_state}</p>
//         <select value={selectedAirConditionOption} onChange={handleSelectChange} id="air_condition">
//           {campaign_data.element_state_options.map((option, index) => (
//             <option key={index} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>

//       </div>
      
//     </div>
//   );
// };

// export default CampaignManagement;

















import React, { useState } from 'react';
import axios from 'axios';
import { base_endpoint } from '../js_resources/initial_states';

const CampaignManagement = ({ campaign_data, last_updated_time }) => {

  const [selectedCampaignOption, setSelectedCampaignOption] = useState(campaign_data.campaign.simulation);
  const [selectedWindowOption, setSelectedWindowOption ] = useState(campaign_data.campaign.window_state);
  const [selectedDoorOption, setSelectedDoorOption ] = useState(campaign_data.campaign.door_state);
  const [selectedBlindOption, setSelectedBlindOption ] = useState(campaign_data.campaign.blind_state);
  const [selectedAirPurifierOption, setSelectedAirPurifierOption ] = useState(campaign_data.campaign.air_purifier_state);
  const [selectedAirConditionOption, setSelectedAirConditionOption ] = useState(campaign_data.campaign.air_condition_state);
 

  const selectStyle = {
    display: 'inline-block',
    margin: '0 10px',
    padding: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };
  const labelStyle = {
    display: 'inline-block',
    marginRight: '5px',
  };

  const controlGroupStyle = {
    display: 'inline-block',
    margin: '10px',
  };

  const handleSelectChange = async (event) => {
   
    
    let put_data = null
    if (event.target.id === "campaign" ){
      put_data = { simulation: event.target.value }
      setSelectedCampaignOption(event.target.value);
      
    }
    else if(event.target.id === "window" ){
      put_data = { window_state: event.target.value }
      setSelectedWindowOption(event.target.value);
    }
    else if(event.target.id === "door" ){
      put_data = { door_state: event.target.value }
      setSelectedDoorOption(event.target.value);
    }
    else if(event.target.id === "blind" ){
      put_data = { blind_state: event.target.value }
      setSelectedBlindOption(event.target.value);
    }
    else if(event.target.id === "air_purifier" ){
      put_data = { air_purifier_state: event.target.value }
      setSelectedAirPurifierOption(event.target.value);
    }
    else if(event.target.id === "air_condition" ){
      put_data = { air_condition_state: event.target.value }
      setSelectedAirConditionOption(event.target.value);
    }
  

    try {
      const response = await axios.put(base_endpoint + "campaign", put_data);
      if (response.status === 200) {
        
      } else {
        alert('Error');
      }
    } catch (error) {
      alert('Error');
    }
  };



  

  return (
    <div className='component' >
      
      <h3 >Campaign Section : {campaign_data.campaign.simulation}</h3>
        <div style={{border: '1px solid black', padding: '30px',}}>
        <div style={controlGroupStyle}>
        <label htmlFor="campaign" style={labelStyle}><b>Campaign:</b></label>
        <select value={selectedCampaignOption} onChange={handleSelectChange} id="campaign" style={selectStyle}>
          {campaign_data.campaign_options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        </div>
        <div style={controlGroupStyle}>
        <p><b>Last updated : </b> {last_updated_time} Seconds</p> 
          </div>
          <div style={controlGroupStyle}>
        <p><b>Row</b> {campaign_data.campaign.current_row} out of {campaign_data.campaign.rows_count}</p> 
          </div>
        <h3>Manual Overide</h3> 

        <div style={controlGroupStyle}>
        <label htmlFor="window" style={labelStyle}><b>Window:</b></label>
        <select value={selectedWindowOption} onChange={handleSelectChange} id="window" style={selectStyle}>
          {campaign_data.element_state_options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        </div>

        <div style={controlGroupStyle}>
        <label htmlFor="door" style={labelStyle}><b>Door:</b></label>
        <select value={selectedDoorOption} onChange={handleSelectChange} id="door" style={selectStyle}>
          {campaign_data.element_state_options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        </div>

        <div style={controlGroupStyle}>
        <label htmlFor="blind" style={labelStyle}><b>Blinds:</b></label>
        <select value={selectedBlindOption} onChange={handleSelectChange} id="blind" style={selectStyle}>
          {campaign_data.element_state_options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        </div>

        <div style={controlGroupStyle}>
        <label htmlFor="air+purifier" style={labelStyle}><b>Air Purifier:</b></label>
        <select value={selectedAirPurifierOption} onChange={handleSelectChange} id="air_purifier" style={selectStyle}>
          {campaign_data.element_state_options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        </div>


        <div style={controlGroupStyle}>
        <label htmlFor="air_condition" style={labelStyle}><b>Air Condition:</b></label>
        <select value={selectedAirConditionOption} onChange={handleSelectChange} id="air_condition" style={selectStyle}>
          {campaign_data.element_state_options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        </div>
        <hr/>
            
          <h3>Curent State</h3>
        
        <div style={controlGroupStyle}>
        <p><b>Window :</b> {campaign_data.campaign.window_state}</p>
        </div>
        <div style={controlGroupStyle}>
        <p><b>Door :</b> {campaign_data.campaign.door_state}</p>
        </div>
        <div style={controlGroupStyle}>
        <p><b>Blind :</b> {campaign_data.campaign.blind_state}</p>
        </div>
        <div style={controlGroupStyle}>
        <p><b>Air purifier :</b> {campaign_data.campaign.air_purifier_state}</p>
        </div>
        <div style={controlGroupStyle}>
        <p><b>Air Condition :</b> {campaign_data.campaign.air_condition_state}</p>
        </div>

      </div>
      
    </div>
  );
};

export default CampaignManagement;

