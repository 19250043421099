
import React, { useState, useEffect, useRef } from 'react';

const Alarms = ({ alarms, campaign}) => {
  const [renderedItems, setRenderedItems] = useState([]);
  const [oldCampaign, setOldCampaign] = useState(campaign);
  
  const audioRef = useRef(null);

  let audioSrc = "./sound2.wav"

  useEffect(() => {
    if (alarms.length > 0) {
      setRenderedItems((prevRenderedItems) => [...prevRenderedItems, ...alarms]);
      if (audioRef.current.paused) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
    }
  }, [alarms]);

  function start_new_campaign(){
    if (oldCampaign !== campaign){
      setRenderedItems([])
      setOldCampaign(campaign)
      console.log("yes")

    }
  }


  return (
    <div className='component' style={{padding:"10px", margin:"0 160px 0 10px", float:"right"}}>
      {start_new_campaign()}
      <h3>Alarm Section</h3>
      <div
        style={{
          overflowY: 'scroll',
          maxHeight: '180px', // Adjust the height based on your needs
          border: '1px solid black',
          padding: '30px',
          minHeight: '180px'
        }}
      >
        {renderedItems.map((item, index) => (
          <div key={index}>
            
            <p style={{color: "red"}}>{item}</p>
            <hr/>
            </div>
        ))}
  
      </div>
      <audio ref={audioRef} style={{ display: 'none' }} preload={'auto'}>
        <source src={audioSrc} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default Alarms;


