import React, { useState } from 'react';
import axios from 'axios';

const DateSelection = () => {
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 16));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 16));
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleButtonClick = async () => {
    setLoading(true);

    setTimeout(()=>{setSuccessMessage('The requested information has been sent to the Authorised email address');}, 3000)
    setTimeout(()=>{setSuccessMessage(''); setLoading(false)}, 10000)

    // try {
    //   const response = await axios.get('https://api.example.com/data', {
    //     params: {
    //       start_date: startDate,
    //       end_date: endDate,
    //     },
    //   });

    //   console.log(response.data);
    //   // Process the data as needed
    // } catch (error) {
    //   console.error('Error fetching data:', error);
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <div className='component' style={{padding:"10px", margin:"50px 0 0 100px"}}>
      <h3 >Download Section</h3>
      <div style={{border: '1px solid black', padding: '30px',}}>
      <label htmlFor="start-date-time"><b>Start Date & Time : </b></label>
      <input
        type="datetime-local"
        id="start-date-time"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <label htmlFor="end-date-time"> <b>End Date & Time : </b> </label>
      <input
        type="datetime-local"
        id="end-date-time"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
      <p></p>
      <button onClick={handleButtonClick} disabled={loading}>
        {loading ? 'Loading...' : 'Download Data'}
      </button>
      <b style={{color:"red", margin:"0 0 0 20px"}}> {successMessage}</b>
    </div>
    </div>
  );
};

export default DateSelection;



