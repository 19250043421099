import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CampaignManagement from './Campaign';
import { base_endpoint, campaign_state } from '../js_resources/initial_states';
import Notifications from './Notifications';
import Alarms from './Alarms';
import Download from './Download';


const Dashboard = () => {
  const [data, setData] = useState(null);
  const [updatedTime, setUpdatedTime] = useState(new Date());
  const [timeSince, setTimeSince] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSince(new Date() - updatedTime);
      axios.get(base_endpoint + 'dashboard')
        .then(response => {
          setUpdatedTime(new Date());
          setData(response.data);
          
        })
        .catch(error => {
          console.log(error);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  function get_last_updated_time(){
    let time_since = Math.floor((new Date() - updatedTime) / 1000);
    return (time_since) > 0 ? time_since : 0
  }

  function display(){
    if (data){
      return (
      <>
        <h1 style={{textAlign:"center"}}>Control Panel</h1> 
        <CampaignManagement campaign_data={data} last_updated_time={get_last_updated_time()}/>
        <Notifications notifications={data.notification.notifications} campaign={data.campaign.simulation} />
        <Alarms alarms={data.notification.alarms} campaign={data.campaign.simulation} />
        <Download/>
        

    
      </>
      )
    }
    return <h1 style={{    textAlign: 'center', margin:'300px 0000',}}>Connecting to Digital Twin</h1>
  }

  return (
    <div>
      {display()}
    </div>
  );
};

export default Dashboard;

