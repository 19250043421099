
import './App.css';
import Dashboard from './components/Dashboard';

function App() {
  return (
   
    <div>
      <Dashboard/>
    </div>
  );
}

export default App;


